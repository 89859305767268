// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Layout
@import "layout/page";
@import "layout/typography";

// Components
@import "components/actions";
@import "components/question";

// General #need to clean-up
.m-header__logo {
    width: 180px;
}

.important {
    text-decoration: underline;
    font-weight: 700;
}

.round-3__option {
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: left;
}

.a-policy {
    display: inline-block !important;

    .form-check-input {
        height: 30px;
    }

    &__label {
        padding-left: 0.3rem;
        font-size: 1.1rem;
    }
}