.a-question {
    @include make-col-ready();
    @include make-col(4);

    padding-left: 0;
    padding-right: 0;
    text-align: center;
    font-size: 2rem;
    position: relative;
    cursor: pointer;

    &.-round_3 {
        padding: 8px;
    }

    &:hover {
        border: 3px $body-bg solid;
    }

    &__wrapper {
        @include make-row();
        text-align: center;

        &.-round_3 {
            margin-top: 20px;
        }
    }

    &__cover {
        position: absolute;
        top: 8px;
        left: 8px;
        right: 8px;
        bottom: 8px;
        background: rgba(#c6c710, 0.85);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;

        &.-round {
            border-radius: 50%;
        }
    }

    &__background {
        background-color: theme-color();

        &.-round {
            border-radius: 50%;
        }
    }
}

.a-quote {
    min-height: 100px;
}
