.m-actions {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: 140px;
    
    button {
        margin-left: 0.25rem; 
        margin-right: 0.25rem; 
    }
}