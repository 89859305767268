// Body
$body-bg: #00a2d4;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$primary: #006a8d;
$secondary: #c6c710;
$body-color: white;

$progress-height: 0.5rem;
$progress-font-size: $font-size-base * 0.75;
$progress-bg: white;
$progress-border-radius: 15px;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba(black, 0.1);
$progress-bar-color: #c6c710;
$progress-bar-bg: #c6c710;
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.6s ease;
