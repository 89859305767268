

.o-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 30px;
        padding-top: 30px;
    }

    &__footer {
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-align: center;
    }
}

.m-content {
    flex: 1;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align:center;
}